<template>
<v-data-table class="elevation-1" :items="data" :headers="headers">
    <template v-slot:item.vigencia="{ item }">
        {{ item.inicio }} - {{ item.fim }}
    </template>
    <template v-slot:item.is_ativo="{ item }">
        <v-icon v-if="item.is_ativo" color="success">mdi-check</v-icon>
        <v-icon v-else color="error">mdi-close</v-icon>
    </template>
    <template v-slot:item.arquivo="{ item }">
        <a :href="`/api/core/download/${item.arquivo}/`" target="_blank">Baixar</a>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small class="mr-2" @click="edit(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
            TODO: Implementar remoção
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small @click="remove(item)">
                    mdi-delete
                </v-icon>
            </template>
            <span>Remover</span>
        </v-tooltip> -->
    </template>
    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Modelos Minuta PDTIC</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="$state.user.is_superuser" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Novo</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Modelo Minuta PDTIC</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-text-field v-model="Item.nome" label="Nome" :error-messages="errors['nome']"></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field v-model="Item.main" label="Aquivo principal" :error-messages="errors['main']"></v-text-field>
                            </v-row>
                            <v-row>
                                <input type="file" accept=".zip" ref="fileInput" label="Arquivo de template" @change="Item.arquivo = $event.target.files[0]" :disabled="isEditing" />
                            </v-row>
                            <v-row>
                                <v-checkbox v-model="Item.is_ativo" label="Template ativo?" disabled></v-checkbox>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn :loading="saving" color="darken-1" text @click="save">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
</v-data-table>
</template>

<script>
import {
    Template, TemplateUpload
} from "@/api/core";
export default {
    name: "Template",

    data: () => ({
        data: [],
        headers: [
            {
                text: "Nome",
                value: "nome",
                align: "center",
            },
            {
                text: "Aquivo principal",
                value: "main",
                align: "center",
            },
            {
                text: "Arquivo de template",
                value: "arquivo",
                align: "center",
            },
            {
                text: "Template ativo?",
                value: "is_ativo",
                align: "center",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                align: "end",
            },
        ],
        Item: {
            main: "main.tex",
            is_ativo: true,
        },
        dialog: false,
        saving: false,
        isEditing: false,
        errors: [],
    }),
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.Item = {
                    main: "main.tex",
                    is_ativo: true,
                };
                this.isEditing = false;
                this.$refs.fileInput.value = "";
            });
        },
        save() {
            this.saving = true;
            this.errors = [];
            const _then = () => {
                this.saving = false;
                this.close();
                this.fetch();
            };
            const _catch = (error) => {
                this.saving = false;
                this.errors = error.response.data;
                if (this.errors["non_field_errors"]) {
                    this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
                } else {
                    this.$emitter.emit("alert:error", this.errors);
                }
            };

            if (this.Item["id"]) {
                let id = this.Item["id"];
                Template.update(id, this.Item)
                    .then(_then)
                    .catch(_catch);
            } else {
                const formData = new FormData();
                formData.append("arquivo", this.Item.arquivo);
                formData.append("main", this.Item.main);
                formData.append("is_ativo", this.Item.is_ativo);
                formData.append("nome", this.Item.nome === undefined ? "" : this.Item.nome);
                TemplateUpload.post(formData)
                    .then(_then)
                    .catch(_catch);
            }
        },
        edit(item) {
            this.isEditing = true;
            this.Item = Object.assign({}, item);
            this.dialog = true;
        },
        // remove(item) {
        //     if (window.confirm("Deseja realmente excluir?")) {
        //     this.tableLoading = true;
        //     Template.remove(item["id"]).then(() => {
        //         this.tableLoading = false;
        //         this.fetch();
        //     });
        //     }
        // },
        fetch() {
            Template.list().then((response) => {
                this.data = response.data;
            });
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>
